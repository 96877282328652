body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.alertSize{
  max-height: 50px !important;
}
.Home .lander {
    
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }
  
  
  .btn-info {
    background-color: #6b9dd4;
  }
  .btn-info:hover {
    font-weight: bold;
    background-color: #1d7de4;
  }
  .btn-info.active {
    font-weight: bold;
    background-color: #1d7de4 !important;
  }

  .orangeICGS {
    background-color: #f1824f !important;
    color: white;
  }
    .orangeICGS:hover {
      font-weight: bold;
      background-color: #ff9558 !important;
      color: white;
    }

  .rc-time-picker {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
  }
  .rc-time-picker * {
    box-sizing: border-box;
  }
  .rc-time-picker-clear {
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 3px;
    margin: 0;
  }
  .rc-time-picker-clear-icon:after {
    content: "x";
    font-size: 12px;
    font-style: normal;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    height: 20px;
    width: 20px;
    transition: color 0.3s ease;
  }
  .rc-time-picker-clear-icon:hover:after {
    color: #666;
  }
  .rc-time-picker-input {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    height: 38px;
    cursor: text;
    font-size: 18px;
    line-height: 1.5;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .rc-time-picker-input[disabled] {
    color: #ccc;
    background: #f7f7f7;
    cursor: not-allowed;
  }
  .rc-time-picker-panel {
    z-index: 1070;
    width: 170px;
    position: absolute;
    box-sizing: border-box;
  }
  .rc-time-picker-panel * {
    box-sizing: border-box;
  }
  .rc-time-picker-panel-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    border: 1px solid #ccc;
    line-height: 1.5;
  }
  .rc-time-picker-panel-narrow {
    max-width: 113px;
  }
  .rc-time-picker-panel-input {
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: auto;
    line-height: 1.5;
    outline: 0;
    border: 1px solid transparent;
  }
  .rc-time-picker-panel-input-wrap {
    box-sizing: border-box;
    position: relative;
    padding: 6px;
    border-bottom: 1px solid #e9e9e9;
  }
  .rc-time-picker-panel-input-invalid {
    border-color: red;
  }
  .rc-time-picker-panel-select {
    float: left;
    font-size: 12px;
    border: 1px solid #e9e9e9;
    border-width: 0 1px;
    margin-left: -1px;
    box-sizing: border-box;
    width: 56px;
    max-height: 144px;
    overflow-y: auto;
    position: relative;
  }
  .rc-time-picker-panel-select-active {
    overflow-y: auto;
  }
  .rc-time-picker-panel-select:first-child {
    border-left: 0;
    margin-left: 0;
  }
  .rc-time-picker-panel-select:last-child {
    border-right: 0;
  }
  .rc-time-picker-panel-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .rc-time-picker-panel-select li {
    list-style: none;
    margin: 0;
    padding: 0 0 0 16px;
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
  }
  .rc-time-picker-panel-select li:hover {
    background: #edfaff;
  }
  li.rc-time-picker-panel-select-option-selected {
    background: #f7f7f7;
    font-weight: bold;
  }
  li.rc-time-picker-panel-select-option-disabled {
    color: #ccc;
  }
  li.rc-time-picker-panel-select-option-disabled:hover {
    background: transparent;
    cursor: not-allowed;
  }
  

  .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #6b9dd4;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.removeMarging p {
      margin-bottom: 0.3rem;

}

.tikdBlue {
  background-color: #50baf0 !important
}
.tikdBlueText {
  color:#50baf0;
}
.closePtag {
  line-height: 5px;
}

.link {
  color:#50baf0; text-decoration: underline;
}

.round{
  border-radius: 25px !important; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.whiteBar{
  border-right: white solid 2px;
   padding-right:25px

}
.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.closeIt:after {
  display: inline-block;
  content: "\D7";
  /* This will render the 'X' */
}
